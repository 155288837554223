.navbar-transparent {
  background-color: transparent !important;
  /* Ensure transparency */
  padding: 20px;
  /* Adjust padding as needed */
}

.nav-link {
  color: white;
  /* Set text color to white */
  font-size: 14px;
  text-transform: uppercase;
  margin-right: 10px;
  /* Optional: Adjust spacing between links */
}

.navbar-brand img {
  max-width: 200px;
}

.navbar-toggler-icon {
  background-image: url('/src/media/nav-icon.svg');
  /* Adjust background-size if needed */
  background-repeat: no-repeat;
  /* Prevent repeating of the icon */
  background-position: center;
  /* Center the icon within the button */
  width: 30px;
  /* Adjust width to match SVG size */
  height: 30px;
  /* Adjust height to match SVG size */
}

.navbar-collapse {
  padding-top: 20px;
  /* Adjust padding as needed */
}

.social a img {
  height: auto;
  width: 20px;
}

@media (max-width: 768px) {

  /* Adjust breakpoint as needed for mobile */
  .navbar-transparent {
    background-color: transparent !important;
    /* Ensure transparency */
    padding: 20px 0px;
    /* Adjust padding as needed */
  }

  .navbar-collapse {
    padding-top: 20px;
    /* Adjust padding as needed */
  }

  .social a img {
    margin-right: 20px;
  }


}
#home {
    height: 90vh;
    position: relative;
    background: url('../media/bg-left.png') left center / auto 100% no-repeat;
}

#home .hero-img img {
    position: absolute;
    max-width: 70%;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
}

#home h1 {
    margin-bottom: 20px;
    letter-spacing: 5px;
    z-index: 1;
    text-shadow: 0px 0px 20px rgba(0, 193, 255, 0.85);
}

#home p {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 5px;
    z-index: 1;
}

#ecosystem {
    padding-top: 120px;
}

.section-title {
    padding-bottom: 60px;
}

#ecosystem .ecosystem-box {
    margin-bottom: 10px;
    padding: 40px 60px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background:
        linear-gradient(#000 0 0) padding-box,
        /*this is your grey background*/
        linear-gradient(to right, #30CDFF, #000) border-box;
    border: 3px solid transparent;
    border-radius: 12px;
}

#ecosystem .ecosystem-box-alt {
    margin-bottom: 10px;
    padding: 40px 60px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background:
        linear-gradient(#000 0 0) padding-box,
        /*this is your grey background*/
        linear-gradient(to left, #30CDFF, #000) border-box;
    border: 3px solid transparent;
    border-radius: 12px
}

.box-number {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    font-weight: bold;
    padding: 10px;
    margin-left: -30px;
    border-radius: 10px;
    background-color: var(--primary-color);
}

.box-number-alt {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    font-weight: bold;
    padding: 10px;
    margin-right: -30px;
    border-radius: 10px;
    background-color: var(--primary-color);
}

.ecosystem-box h3 {
    margin-top: 0;
    /* Remove top margin from title */
    flex: 1;
    /* Allow title to occupy remaining space */
}

#tokenomics {
    padding: 60px 0px;
}

#tokenomics .distribution-box {
    margin-left: 40px;
    width: 100%;
}

#tokenomics .distribution-box span {
    color: #000;
    font-weight: 600;
}

#tokenomics .dist-0,
#tokenomics .dist-1,
#tokenomics .dist-2,
#tokenomics .dist-3,
#tokenomics .dist-4,
#tokenomics .dist-5,
#tokenomics .dist-6,
#tokenomics .dist-7,
#tokenomics .dist-8,
#tokenomics .dist-9 {
    padding: 10px 20px;
    margin-bottom: 10px;
    border-radius: 12px;
}

#tokenomics .dist-0 {
    background: linear-gradient(90deg, #28AEEA 1.64%, rgba(22, 22, 22, 0) 97.22%);
}

#tokenomics .dist-1 {
    background: linear-gradient(90deg, #FE5142 1.64%, rgba(178, 61, 51, 0.672763) 32.91%, rgba(22, 22, 22, 0) 97.22%);
}

#tokenomics .dist-2 {
    background: linear-gradient(90deg, #FDBD06 1.64%, rgba(22, 22, 22, 0) 97.22%);
}

#tokenomics .dist-3 {
    background: linear-gradient(90deg, #38DE8D 1.64%, rgba(22, 22, 22, 0) 97.22%);
}

#tokenomics .dist-4 {
    background: linear-gradient(90deg, #973FA6 1.64%, rgba(22, 22, 22, 0) 97.22%);
}

#tokenomics .dist-5 {
    background: linear-gradient(90deg, #3774D8 1.64%, rgba(22, 22, 22, 0) 97.22%);

}

#tokenomics .dist-6 {
    background: linear-gradient(90deg, #FF7144 1.64%, rgba(22, 22, 22, 0) 97.22%);
}

#tokenomics .dist-7 {
    background: linear-gradient(90deg, #EDEC37 1.64%, rgba(22, 22, 22, 0) 97.22%);
}

#tokenomics .dist-8 {
    background: linear-gradient(90deg, #5C6CC1 1.64%, rgba(22, 22, 22, 0) 97.22%);
}

#tokenomics .token-address .address{
    color: #30CDFF;
    font-weight: 600;
    margin-right: 5px;
}

#tokenomics .token-address {
    margin-top: 40px;
    padding: 40px 10px;
    border: 1px solid;
    border-image: radial-gradient(circle at center, #30CDFF, #000);
    /* Adjust colors as needed */
    border-image-slice: 1;
}

#tokenomics p {
    margin: 0px;
}

#tokenomics .sol-img {
    max-width: 24px;
    margin-right: 10px;
}

#tokenomics .token-address span {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
}

.copy-btn {
    margin-left: 15px;
    padding: 0px;
    font-size: 20px;
    border: none;
    color: var(--primary-color);
    background-color: transparent;
    cursor: pointer;
}


.tooltip-show {
    display: block;
    position: absolute;
    /* Adjust the position as needed */
    left: 50%;
    /* Adjust the position as needed */
    transform: translateX(-50%);
    background-color: var(--primary-color);
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 999;
}

.tooltip-hide {
    display: none;
}

#launch-app {
    padding: 60px 0px;
}

#launch-app .app {
    background: url('../media/app-bg.png');
    background-size: cover;
    background-position: center;
}

#launch-app .app-img {
    max-width: 60%;
}

#launch-app .img-video {
    max-width: 80%;
}

#feature {
    padding: 60px 0px;
}

#feature .feature-item {
    margin-bottom: 10px;
    padding: 10px 40px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background:
        linear-gradient(#000 0 0) padding-box,
        /*this is your grey background*/
        linear-gradient(to right, #30CDFF, #000) border-box;
    border: 3px solid transparent;
    border-radius: 12px
}

#feature .feature-item p {
    margin: 0px;
}

#solutions {
    padding: 60px 0px;
}

#solutions .screenshot {
    margin-top: -100px;
}

/* #solutions .solutions-box{
    margin-bottom: 20px;
    padding: 40px 60px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border: 1px solid;
    border-image: linear-gradient(to right, #30CDFF, #000);
    border-image-slice: 1;
} */

#solutions .solutions-box {
    margin-bottom: 20px;
    padding: 40px 60px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background:
        linear-gradient(#000 0 0) padding-box,
        /*this is your grey background*/
        linear-gradient(to right, #30CDFF, #000) border-box;
    border: 3px solid transparent;
    border-radius: 12px
}

#solutions .box-text {
    margin-left: 60px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

#solutions p {
    margin: 0px;
    font-size: 14px;
}

#solutions .box-img {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
}

#solutions .box-img img {
    max-width: 50%;
}

#roadmap {
    padding: 60px 0px;
}

#roadmap .map {
    padding: 40px 20px;
    background: url('../media/map-bg.png');
    background-size: 50%;
    background-position: top left;
    background-repeat: no-repeat;
}

/* #roadmap .map-1{
    margin-top: 100px;
}

#roadmap .map-2{
    margin-top: 75px;
}

#roadmap .map-3{
    margin-top: 50px;
}

#roadmap .map-4{
    margin-top: 25px;
} */

#roadmap ul {
    padding: 1rem;
}

#roadmap ul li {
    color: #e8e8e8;
    font-size: 14px;
}

.curved-line {
    position: relative;
}

.curved-line img {
    position: absolute;
    top: 100px;
    left: 15%;
    transform: translate(-50%, -50%);
    z-index: -1;
    width: 200px;
    height: auto;
}

#partners {
    padding: 60px 0px;
}

#partners img {
    max-width: 150px;
}

#pre-footer {
    padding: 60px 0px;
}

#pre-footer .pre-1 {
    padding: 60px;
    border-radius: 12px;
    background: linear-gradient(0deg, #30CDFF 0%, #FFEB00 156.29%);
}

#pre-footer .pre-1 h3 {
    color: #000;
    text-transform: uppercase;
}

#pre-footer .pre-1 p {
    color: #000;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0px;
}

#pre-footer .pre-2 {
    position: relative;
    padding: 60px;
    border-radius: 12px;
    background: linear-gradient(180deg, #30CDFF 0%, #FF71F9 0.01%, #0A39B3 95.88%);
}

#pre-footer .pre-2 p {
    margin-right: 400px;
    font-size: 22px;
}

#pre-footer img {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 400px;
}

/* Media Queries for Responsive Adjustments */

@media (max-width: 768px) {
    .section-title {
        padding-bottom: 40px;
    }

    #home {
        background-size: auto 100%;
        height: 100%;
        padding-top: 100px;
    }

    #home .intro {
        text-align: center;
    }

    #home .hero-img img {
        position: static;
        max-width: 100%;
        top: auto;
        bottom: auto;
        transform: none;
    }

    #home p {
        font-size: 18px;
        line-height: 28px;
    }

    #ecosystem {
        padding: 60px 20px 0px 20px;
    }

    #ecosystem .ecosystem-box,
    #ecosystem .ecosystem-box-alt,
    #solutions .solutions-box {
        padding: 20px 20px 20px 50px;
    }

    #ecosystem .ecosystem-box-alt {
        margin-bottom: 10px;
        position: relative;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        background:
            linear-gradient(#000 0 0) padding-box,
            /*this is your grey background*/
            linear-gradient(to right, #30CDFF, #000) border-box;
        border: 3px solid transparent;
        border-radius: 12px;
    }

    .box-number-alt {
        position: absolute;
        left: 10px;
        right: auto;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
        font-weight: bold;
        padding: 10px;
        margin-left: -30px;
        border-radius: 10px;
        background-color: var(--primary-color);
    }

    #tokenomics .token-address {
        flex-direction: column;
    }

    #tokenomics .token-address .cop-btn {
        margin-left: 0px !important;
    }

    #tokenomics .token-address .address {
        font-size: 10px;
        padding: 20px 0px;
    }

    .copy-btn {
        margin-left: 0px;
    }


    #tokenomics .distribution-box {
        margin-left: 0px;
        width: 100%;
    }


    #launch-app {
        padding-bottom: 0px;
    }

    #launch-app .app {
        margin-bottom: 20px;
    }

    #feature {
        padding-bottom: 0px;
    }

    #tokenomics .dist-0,
    #tokenomics .dist-1,
    #tokenomics .dist-2,
    #tokenomics .dist-3,
    #tokenomics .dist-4,
    #tokenomics .dist-5,
    #tokenomics .dist-6,
    #tokenomics .dist-7,
    #tokenomics .dist-8,
    #tokenomics .dist-9 {
        padding: 10px 60px 10px 20px;

    }

    #tokenomics {
        padding-bottom: 0px;
    }

    #solutions {
        padding-bottom: 0px;
    }

    #solutions .screenshot {
        margin-top: -50px;
    }

    #feature .feature-item{
        padding: 10px 20px;
    }

    #roadmap .map {
        padding-top: 20px;
        padding-bottom: 0px;
    }

    #roadmap {
        padding-bottom: 0px;
    }

    #partners {
        padding-bottom: 0px;
    }

    #pre-footer .pre-1 {
        padding: 60px 20px;
    }

    #pre-footer .pre-2 {
        padding: 60px 20px;
    }

    #pre-footer .pre-2 p {
        margin: 0px;
    }

    #pre-footer .pre-2 img {
        margin-top: 20px;
        position: static;
        top: auto;
        bottom: auto;
        right: auto;
        left: auto;
        max-width: 100%;
    }

    #footer {
        padding: 20px !important;
    }

}
@font-face {
    font-family: 'Benzin-ExtraBold';
    src: url('./fonts/Benzin-ExtraBold.ttf') format('truetype');
}

:root {
    --primary-color: #30cdff;
}

body {
    color: #fff;
    background-color: #000;
    font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Benzin-ExtraBold";
    text-transform: uppercase;
    color: var(--primary-color);
}
#footer{
    padding: 60px 0px 40px 0px;
}

#footer p{
    font-size: 14px;
    color: #e8e8e8;
    margin-bottom: 0px;
}

#footer .text a{
    text-decoration: none;
    color: #30CDFF;
}

#footer .menu ul {
    list-style: none;
    padding: 0;
  }
  
  #footer .menu li {
    display: inline;
    margin-right: 15px; /* Adjust as needed for spacing */
  }
  
  #footer .menu li a {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff; /* Inherit text color from parent */
  }

  #footer .menu li a:hover{
    color: #30CDFF;
  }
  
  @media only screen and (max-width: 600px) {
    #footer .text{
      font-size: 12px;
    }
    #footer .menu ul {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
    }
  
    #footer .menu li {
      display: flex;
     
      margin-bottom: 20px; /* Adjust as needed for spacing */
    }
  }
  